.asp-calendar table {
  margin: 0 auto !important;
  width: 100%;
  height: 100%;
  border-collapse: collapse !important;
  /* border-collapse: separate !important; */
  border-spacing: 0px 0px !important;
}
.popOverVariant-calendar {
  width: 300px;
  box-shadow: 0px 4px 30px 0px #2d30361a;
  border-radius: 10px;
  padding: 5px 10px 10px 10px;
}
.asp-calendar table,
tr,
td,
th {
  border: none !important;
}

.current-today {
  background-color: var(--primary) !important;
  color: var(--textBlack);
  width: 30px;
  height: 25px;
  padding-top: 5px;
  text-align: center !important;
  border-radius: 13px;
}

.asp-calendar .heading {
  font-size: 0.85vw;
  font-weight: 600;
  letter-spacing: 0.2px;
  margin-left: 10px;
}
.asp-calendar .today {
  background-color: var(--primary) !important;
  color: var(--textBlack);
  width: 30px;
  margin-left: 10px;
  /* height: 25px; */
  text-align: center;
  border-radius: 13px;
}
.asp-calendar .in-prev-month,
.asp-calendar .in-next-month {
  /* opacity: 0.5; */
  color: var(--font-100) !important;
}
.asp-calendar td {
  border: 0px solid transparent;
  font-size: 0.75vw;
  font-weight: 500;
  text-align: center;
  color: var(--font-900);
  /* cursor: pointer; */
  /* border-radius: 20px; */
  width: calc(100% / 7);
  /* width: 36px;
  height: 27px; */
  /* background: red; */
}
.asp-calendar th {
  /* padding: 7px 10px; */
  text-align: center;
  font-size: 0.65vw;
  font-weight: 400;
  text-transform: capitalize;
  color: var(--font-600);
}
.monthWithYear {
  font-size: 0.85vw;
  font-weight: 600;
  letter-spacing: 0.2px;
  /* width: 80px; */
  text-align: center;
  padding-left: 5px;
  color: var(--textBlack);
}
.asp-calendar .arrow {
  width: 17px;
  height: 17px;
  margin: 0 5px;
}

.red {
  background-color: red;
}
/* custom */
.weekDays {
  text-align: center;
  font-size: 0.65vw;
  font-weight: 400;
  text-transform: capitalize;
  color: var(--font-600);
  width: calc(100% / 7);
}
.calendarWrapper {
  margin: 0 auto !important;
  width: 100%;
  height: 100%;
}
.daysWrapper {
  height: 30px;
}
.dateRow {
  height: calc(100% / 7);
}
.individualDate {
  font-size: 0.7vw;
  font-weight: 600;
  text-align: center;
  color: #616874;
  /* color: var(--font-900); */
  width: calc(100% / 7);
  height: 25px;
  border: none;
  position: relative;
}
.asp-calendar .activeBox {
  background-color: var(--primary);
  color: var(--textBlack);
  width: 32px;
  height: 25px;
  text-align: center;
  border-radius: 13px;
}
.left-horizontalLine {
  position: absolute;
  left: 0;
  right: 0;
  width: 12px;
  height: 15px;
  background-color: var(--primaryLight);
}
.right-horizontalLine {
  position: absolute;
  right: 0;
  width: 12px;
  height: 15px;
  background-color: var(--primaryLight);
}
.asp-calendar .horizontalLine {
  width: 100% !important;
  background-color: var(--primaryLight);
  height: 15px;
}
