.weekCalendarWrapper {
  /* width: 100%; */
  width: calc(100% / 7);
  height: 100vh !important;
  background: var(--background);
  border-right: 0.5px solid var(--border-50);
  /* margin-bottom: 30px;
  padding: 0 20px; */
}

.weekDayWrapper {
  border-right: 0.5px solid var(--border-50) !important;
  border-bottom: 0.5px solid var(--border-50) !important;
  width: calc(100% / 7);
  /* height: 45px; */
}

.weekCalendarWrapper .weekDays {
  width: 100%;
  height: 50px;
  padding-left: 20px;
  /* border-right: 0.5px solid #E8E8EA !important;
  border-bottom: 0.5px solid #E8E8EA !important;  */
  /* border-bottom: 0.5px solid var(--border-50); */
}

.weekCalendarWrapper .weekDays .days {
  font-size: 0.75vw;
  font-weight: 500;
  letter-spacing: 0.2px;
}

.weekCalendarWrapper .weekDays .date {
  /* font-size: 1.1vw; */
  font-size: 0.75vw;
  font-weight: 600;
  letter-spacing: 0.2px;
}

.daysTaskCard {
  width: 100%;
  height: 100%;
  min-height: 250px;
  max-height: 400px;
  overflow-y: scroll;
}

.week1 {
  /* border: 1px solid green !important; */
  width: 100%;
  border-right: 0.5px solid var(--border-50) !important;
  border-bottom: 0.5px solid var(--border-50) !important;
  height: 65px;
  /* max-height: auto; */
  /* padding-left: 10px; */
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.first-cell {
  height: 35px;
  border-right: 0.5px solid var(--border-50) !important;
  border-bottom: 0.5px solid var(--border-50) !important;
}

.timimg2 {
  width: 5% !important;
  border-right: 1px solid var(--border-50);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--background);
}

.timimg {
  width: 100%;
  height: 53px;
  border-bottom: 1px solid var(--border-50);
  display: flex;
  justify-content: center;
  align-items: center;
}

.gmt {
  width: 50%;
  margin: auto;
  font-size: 0.5vw;
  color: #898e99;
  font-weight: 500;
  text-align: center;
}
