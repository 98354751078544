/* 16px - 500 */

.font16px-500 {
  font-size: 0.975rem;
  font-weight: 500;
}

/* 14px */
.font14px-500 {
  font-size: 0.875rem;
  font-weight: 500;
}
.font14px-400 {
  font-size: 0.875rem;
  font-weight: 400;
}
.font15px-400 {
  font-size: 0.92rem;
  font-weight: 400;
}

/* 13px - 400 */

.font13px-400 {
  font-size: 0.8rem;
  font-weight: 400;
}
