.tooltip {
  position: relative;
  display: inline-block;
  z-index: 1099 !important;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: var(--black);
  color: var(--background);
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 50 !important;
  bottom: 85%;
  /* left: 50%; */
  /* margin-left: -60px; */
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  letter-spacing: 0.02rem;
  font-weight: 400;
  font-size: 0.8vw;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: var(--black) transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  z-index: 1099 !important;
}
