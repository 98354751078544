.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
}

/* :where(.css-dev-only-do-not-override-98ntnt).ant-checkbox-wrapper:not(
    .ant-checkbox-wrapper-disabled
  ):hover
  .ant-checkbox-inner,
:where(.css-dev-only-do-not-override-98ntnt).ant-checkbox:not(
    .ant-checkbox-disabled
  ):hover
  .ant-checkbox-inner {
  border: red !important;
} */

:where(.css-dev-only-do-not-override-98ntnt).ant-checkbox:not(
    .ant-checkbox-disabled
  ):hover
  .ant-checkbox-inner {
  border-color: inherit !important;
}
