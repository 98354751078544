.new-round {
  position: relative;
  border: 1.5px solid var(--border-100);
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.new-round label {
  border-radius: 50%;
  cursor: pointer;
  height: 12px !important;
  left: 2.9px;
  top: 2.9px;
  /* left: 1.3px; */
  position: absolute;
  /* top: 1.5px; */
  width: 12px !important;
  /* margin: 1.2px; */
}

.new-round label:after {
  /* border: 1.5px solid var(--new-primary);
  border-radius: 50%;
  border-top: none;
  border-right: none;
  content: "";
  height: 5px;
  left: 48%;
  opacity: 0;
  position: absolute;
  top: 8%;
  transform: rotate(-45deg) translate(-45%, -20%);
  width: 5px;
  display: flex;
  justify-content: start;
  align-items: center; */
}

.new-round input[type="checkbox"] {
  visibility: hidden;
}

.new-round input[type="checkbox"]:checked + label {
  position: absolute;
  background-color: var(--new-primary);
  border-color: var(--new-primary-bg);
}

.new-round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
