.whatsappDashboard {
  /* background: white;
  padding: 1% 2%;
  height: 100%;
  overflow-y: scroll !important; */
  background: white;
  padding: 16px 26px 7% 26px;
  /* padding: 0px 26px; */
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

/* .viewAllBtn {
  font-size: 0.85vw;
  padding: 6px 8px;
  border-radius: 6px;
  font-weight: 600;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.02rem;
  color: var(--primary);
}

.whatsappHeaderContainer {
  display: flex;
  gap: 1vw;
  margin-top: 0.6vw;
  height: 38%;
  margin-bottom: 2vw;
}

.categoryImageDescContainer {
  width: 50%;
  height: 94.5%;
  border: 1px solid var(--border-50);
  box-shadow: 0px 1px 2px 0px var(--border-50);
  border-radius: 16px;
  padding: 0.6vw 0.7vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
}

.image-container {
  position: relative;
  width: 180px;
  height: 180px;
  border: 1px solid #ccc;
  text-align: center;
  background-color: var(--primary) !important;
}

.WAPhotoUploaderContainer {
  width: 100%;
  height: 80%;
  display: flex;
  gap: 2vw;
  position: relative;
}

.WAPhotoUploader {
  width: 35%;
  height: 100%;
}

.usernameWithoutImage {
  text-transform: capitalize;
  color: #000;
  position: absolute;
  width: 100%;
  height: auto;
  font-size: 0.8vw;
  font-weight: 500;
  bottom: 2%;
  left: 2%;
  font-weight: 600;
}

/* .username::after {
  content: "";
  width: 35%;
  color: #fff;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0) 95%
  );
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
} */

.categoryDescContainer {
  width: 65%;
  height: auto;
  display: flex;

  flex-direction: column;
  /* padding: 10px 10px; */
}

.categoryDescContainer .WADescriptionTextArea {
  /* min-height: 9.5vh !important;
  max-height: 14vh !important; */
  font-size: 0.75vw;
  font-weight: 500;
  color: var(--textBlack);
}

.waTableContainer {
  margin-top: 2vw;
}
.whatsappHeaderContainer {
  display: flex;
  gap: 1vw;
  margin-top: 0.6vw;
  height: 38%;
  margin-bottom: 2vw;
}

.categoryImageDescContainer {
  width: 50%;
  /* height: 33vh; */
  border: 1px solid var(--border-50);
  box-shadow: 0px 1px 2px 0px var(--border-50);
  border-radius: 16px;
  padding: 0.6vw 0.7vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.viewAllBtn {
  font-size: 0.85vw;
  padding: 6px 8px;
  border-radius: 6px;
  font-weight: 600;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.02rem;
  color: var(--primary);
}

.image-container {
  position: relative;
  width: 180px;
  height: 180px;
  border: 1px solid #ccc;
  text-align: center;
  background-color: var(--primary) !important;
}

.WAPhotoUploaderContainer {
  width: 100%;
  height: 80%;
  display: flex;

  position: relative;
}

.WAPhotoUploader {
  width: 50%;
  height: 100%;
}

.usernameWithoutImage {
  text-transform: capitalize;
  color: #000;
  position: absolute;
  width: 100%;
  height: auto;
  font-size: 0.8vw;
  font-weight: 500;
  bottom: 2%;
  left: 2%;
  font-weight: 600;
}

.categoryDescContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 0 0 20px;
}

.categoryDescContainer .WADescriptionTextArea {
  min-height: 9.5vh !important;
  max-height: 14vh !important;
}

.waTableContainer {
  margin-top: 2vw;
}

.whatsapp-model {
  height: 93vh;
  background-color: white;
}
