.broadCast-card {
  border: 1px solid var(--border-50);
  border-radius: 16px;
  display: flex;
  box-shadow: 0px 1.5px 2px 0px rgba(16, 24, 40, 0.1);
  padding: 10px 30px;
}

.broadcastImage {
  width: 3vw;
  height: 3vw;
}
.postCard-left > span:nth-child(1) {
  padding: 0px 5px;
  margin-bottom: 6px;
  font-size: 0.78vw;
  font-weight: 600;
  color: #2d3036eb;
}
.postCard-left > span:nth-child(2) {
  padding: 0px 5px;
  color: var(--textBlack);
  font-size: 1.146vw;
  font-weight: 600;
}
.Overview_container1 {
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  /* padding: 10px 20px; */
  /* padding-bottom: 20px; */
}

.Overview_container > span {
  font-size: 0.938vw;
  font-weight: 600;
}
