.svgLoaderLoader {
  position: absolute;
  width: 240px;
  height: 120px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.svgLoader #plug,
.svgLoader #socket {
  fill: url(#gradient);
}

.svgLoader #loop-normal {
  fill: none;
  stroke: url(#gradient);
  stroke-width: 12;
}

.svgLoader #loop-offset {
  display: none;
}

.credit {
  position: absolute;
  bottom: 50px;
  width: 100%;
  text-align: center;
}

.credit a {
  color: #fdb515;
  font:
    800 75% 'Open Sans',
    sans-serif;
  text-transform: uppercase;
  text-decoration: none;
}
