
.explain-error {
  color: var(--fieldWarning) !important;
  font-weight: 500 !important;
  font-size: 0.75rem !important;
  margin-top: 5px !important;
}
.footerImgBig {
  width: 28px;
  height: 28px;
  z-index: 200 !important;
}

.anotherClassName {
  width: 25px !important;
  height: 25px !important;
}
.addChannel {
  width: 35px;
  height: 35px;
  z-index: 0 !important;
  margin-left: -20px;
}
.footerImgSmall {
  width: 25px;
  height: 25px;
}
.footerBox {
  width: 25%;
  height: 50px;
  cursor: pointer;
}
.draft {
  font-size: 0.8vw;
  font-weight: 600;
  letter-spacing: 0.2px;
  margin-right: 20px;
}

.footerBox .dropDown {
  bottom: 35px;
  left: -50px;
}

.header-switching-button-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  border-bottom: 1px solid var(--border-50);
}
.header-switching-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 20px;
  height: 45px;
}

.header-switching-button-wrapper-active {
  border-bottom: 2px solid var(--borderPrimary) !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 20px;
  height: 45px;
}
.HeaderBorderLeft {
  width: 4px;
  height: 35px;
  background: #f96056;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.header-switching-button-name {
  font-size: 0.75vw;
  font-weight: 400;
  color: var(--textBlack);
  padding-left: 5px;
}

.header-switching-line {
  border-right: 1px solid var(--border-100);
  height: 25px;
}

.header-switching-button-wrapper > img {
  width: 13px;
  height: 13px;
}
.calender-container > img {
  background-color: var(--BG-50);
  border-radius: 50%;
  width: 15px;
  height: 15px;
}

.calendarTitle {
  font-size: 0.95vw;
  font-weight: 700;
  letter-spacing: 0.2px;
  color: var(--textBlack);
  margin: 0 5px;
}

.switchingButtons {
  height: 45px;
  border-bottom: 1px solid var(--border-50);
}

.date-popover .ant-popover-content {
  right: 85px !important;
}

@media (min-width: 768px) {
  .date-popover .ant-popover-content {
    right: 100px !important; 
  }
}

@media (min-width: 1920px) {
  .date-popover .ant-popover-content {
    right: 180px !important;
  }
}

@media (max-width: 767px) {
  .date-popover .ant-popover-content {
    right: 50px !important; 
  }
}