.homeHeader {
  width: 100%;
  border-bottom: 1px solid var(--layoutBorder);
  height: 5vh;
  /* padding: 0 20px; */
  /* margin-left: auto;
  margin-right: 0; */
  /* overflow: hidden; */
  /* white-space: nowrap; */
  /* border: 1px solid red; */
  /* flex-direction: column; */
  /* justify-content: center; */
}

.scrolling-text {
  display: flex;
  justify-items: center;
  align-items: center;
  font-weight: 500;
  animation: scroll-left 10s linear infinite;
}

@keyframes scroll-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
