.textEditorContainer {
  width: 100%;
  border: 0.08vw solid var(--border-input);
  border-radius: 0.7vw;
  position: relative;
  margin-bottom: 1vw;
  padding-bottom: 0.7vw;
}

.textEditorContainer1 {
  height: 20vh;
}

.textEditorContainer .editor {
  width: 100%;
  height: 90%;
  overflow: scroll;
  font-size: 0.75vw;
  font-weight: 400;
  padding: 0.5vw;
  color: var(--contentText) !important;
}

.textEditorContainer .public-DraftEditorPlaceholder-inner {
  position: absolute;
  color: var(--darkgrey);
  font-size: 0.8rem;
  font-weight: 300;
  color: #8796AF;
}

.DraftEditor-root,
.DraftEditor-editorContainer,
.public-DraftEditor-content {
  width: 100%;
  height: 100%;
}

.DraftEditor-editorContainer {
  z-index: 11;
  position: relative;
}
