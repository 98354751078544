.whatsappHeader {
  width: 100%;
  background: var(--textWhite);
  border-bottom: 0.08vw solid var(--font-50);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1vw 1.4vw 0.74vw;
}

.whatsappHeader-left > img {
  width: 2.865vw;
  border: 0.1vw solid var(--borderPrimary);
  border-radius: 50%;
  padding: 0.15vw;
}

.whatsappHeader-name {
  font-size: 0.95vw;
  font-weight: 700;
  color: var(--textBlack);
  letter-spacing: 0.3px;
}

.whatsappHeader-nameId {
  font-size: 0.75vw;
  font-weight: 400;
  color: var(--font-400);
  letter-spacing: 0.3px;
}

.greenMark {
  background-color: var(--primary);
  height: 8px;
  width: 8px;
  margin: auto 10px;
  border-radius: 2px;
}

.header-contain {
  font-size: 0.729vw;
  font-weight: 400;
  color: #898e99;
}

.url-container {
  padding: 20px;
}
