.calendar {
  width: auto;
  border-radius: 8px;
}

.calendarMargin {
  position: absolute;
  right: 20px !important;
}

/* .date-input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  text-align: center;
} */

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  color: #2d3036;
  font-weight: 600;
  font-size: 0.875rem;
  padding: 15px;
  border-bottom: 1px solid #e8e8ea;
}

.calendar-days {
  color: #898e99;
  font-size: 0.875rem;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  padding: 15px;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  border-bottom: 1px solid #e8e8ea;
  padding: 15px;
}

.calendar-day {
  text-align: center;
  padding: 10px;
  font-weight: 500;
  color: #2d3036;
  font-size: 0.875rem;
}

.day-name {
  text-align: center;
  padding: 5px;
  color: #898e99;
  font-weight: 500;
  font-size: 0.875rem;
}

.calendar-day.selectable:hover {
  cursor: pointer;
  background-color: #f0f0f0;
  border-radius: 8px;
}

/* .calendar-day.selectable.selected {
  background-color: #05805c;
  color: white !important;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
} */

/* Start Date Styling */
.calendar-day.selectable.start-date {
  background-color: #05805c;
  color: white !important;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

/* End Date Styling */
.calendar-day.selectable.end-date {
  background-color: #05805c;
  color: white !important;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.calendar-day.in-range {
  background-color: #f1f4f6;
  color: #333;
}

.input-type {
  width: 50%;
  border: 1px solid #e8e8ea;
  border-radius: 8px;
  text-align: center;
  padding: 0.4rem 0rem;
}
