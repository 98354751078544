.profile-contact-options {
  border-bottom: 1px solid var(--border-50);
}
.profile-contact-options:last-child {
  border-bottom: none;
}

.profile-contact {
  height: 3.6vh;
  border: none;
}

.profile-contact:hover {
  border: 1px solid var(--border-50);
}

/* Profile information */

.profile-information {
  border: 1px solid var(--border-50);
}

.opted_in {
  background-color: #eefff1;
  border-radius: 5px;
  color: var(--primary);
  font-size: 0.6vw;
  font-weight: 500;
  padding: 5px;
}

.opted_out {
  background-color: var(--lightRed);
  border-radius: 5px;
  color: var(--fontRed);
  font-size: 0.6vw;
  font-weight: 500;
  padding: 5px;
}

.contact-primary {
  background-color: #3a85c4;
  color: var(--white);
  font-size: 0.7vw;
  font-weight: 500;
  padding: 7px 10px;
  border-radius: 6px;
  /* text-align: center; */
}
.mark-primary {
  background-color: var(--channelButton);
  width: 70%;
  padding-left: 8px;
  border-radius: 6px;
  font-size: 0.7vw;
  font-weight: 500;
  color: var(--font-600);
}

.mark-primary2 {
  background-color: var(--channelButton);
  width: 100%;
  padding: 6px 10px;
  border-radius: 6px;
  font-size: 0.7vw;
  font-weight: 500;
  color: var(--font-600);
}

.mark-primary1 {
  background-color: var(--channelButton);
  width: 70%;
  padding: 6px 8px;
  border-radius: 6px;
  font-size: 0.7vw;
  font-weight: 500;
  color: var(--font-600);
}

.mark-primary > span {
  color: var(--textBlack);
  font-size: 0.7vw;
  font-weight: 500;
  padding: 7px 10px;
  border-radius: 6px;
}

.info-headings {
  font-size: 0.9vw;
  font-weight: 500;
  color: var(--textBlack);
}

.add-number {
  background-color: var(--primary);
  color: var(--white);
  font-size: 0.75vw;
  font-weight: 500;
  width: 17%;
  border-radius: 6px;
}

.information-value {
  font-size: 0.9vw !important;
  font-weight: 400 !important;
  color: var(--font-600) !important;
}

.information-value1 {
  font-size: 0.9vw !important;
  font-weight: 400 !important;
  color: var(--font-600) !important;
}


.info-bottom {
  border-bottom: 1px solid var(--border-50);
}

/* .custom-datepicker .ant-picker-input {
  border: none;
  box-shadow: none;
}

.custom-datepicker .ant-picker-input:hover {
  border: none !important;
  box-shadow: none !important;
} */

/* .pickersss {
  background: red !important;
} */

.ant-picker-outlined:hover {
  border: 1px solid var(--border-50) !important;
}

:where(.css-dev-only-do-not-override-98ntnt).ant-picker-outlined {
  /* border: 1px solid var(--border-50) !important; */
  border: none !important;
}

/* :where(.css-dev-only-do-not-override-98ntnt).ant-picker {
  color: var(--font-600);
  font-size: 0.9vw;
  font-weight: 400;
} */

.ant-picker-outlined:focus-within {
  box-shadow: none !important;
}

.date-picker-crm {
  color: white !important;
  font-size: 0.9vw !important ;
  font-weight: 400 !important;
  padding: 0;
  width: 100%;
  height: 0vh;
  border: none !important;
}

.date-picker-inbox {
  color: white !important;
  font-size: 0.9vw !important;
  font-weight: 400 !important;
  padding: 0;
  width: 100%;
  height: 0vh;
  border: none !important;
  right: 300px;
  top: 20px;
}

.ant-picker .ant-picker-input > input {
  color: white !important;
  display: none;
}

.date-picker-input {
  font-size: 0.9vw !important ;
  font-weight: 400 !important;
  color: var(--font-600) !important;
  padding-top: 0px 7px;
  height: 4vh;
}
.date-picker-input:hover {
  padding: 0px 7px;
  border: 1px solid var(--border-50);
  border-radius: 6px;
  width: 100%;
}

.ellipsis-container {
  width: 100%;
  font-size: 0.85vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 2px;
}

.crm-border {
  border-bottom: 1px solid var(--border-50);
}
