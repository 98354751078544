.asp-modal-popup-small {
  width: 35vw !important;
  min-width: 250px;
  top: 240px;
  right: 0%;
  margin-bottom: 20px;
  padding: 0 !important;
  height: auto;
  overflow: hidden;
}
.asp-modal-popup-medium {
  width: 50vw !important;
  min-width: 650px;
  top: 240px;
  right: 0%;
  /* max-height: 60vh; */
  margin-bottom: 20px;
  padding: 0 !important;
  height: auto;
}
.asp-modal-popup-large {
  width: 67vw !important;
  min-width: 650px;
  top: 240px;
  right: 0%;
  margin-bottom: 20px;
  padding: 0 !important;
  height: auto;
}
.asp-modal-popup-channels {
  width: 30vw !important;
  min-width: 250px;
  height: auto;
  top: 240px;
  right: 0%;
  margin-bottom: 20px;
  padding: 0 !important;
  height: auto;
  overflow: hidden;
}
.popup-header {
  width: 100%;
  padding: 12px 20px;
  /* border-bottom: 1px solid var(--authDivider); */
}
.popup-body {
  width: 100%;
  height: 550px !important;
  padding: 20px !important;
  overflow-y: scroll !important;
  overflow-x: visible !important;
}
.popup-footer {
  width: 100%;
  padding: 10px 20px 20px 20px;
  box-shadow: 0px -1.5px 2px 0px #1018281a;
}
.popup-header-text {
  font-size: 0.75vw;
  font-weight: 700;
  letter-spacing: 0.02vw;
  color: var(--textBlack);
}

.popup-header-content {
  font-size: 0.75vw;
  color: var(--font-400);
  font-weight: 400;
}
.popup-exit {
  width: 20px;
  height: 20px;
}
.popup-exit:hover {
  border-radius: 6px;
  background-color: var(--channelButton);
}

.alertInfoWrapper {
  background: #fff;
  border-radius: 10px;
}

.alertInfoWrapper .close {
  height: 20px;
  width: 20px;
}

.alertInfoWrapper .alert-icons {
  height: 35px;
  width: 35px;
}

.alertInfoWrapper .header {
  height: 30px;
}

.header {
  /* transform: translate(115px); */
  /* margin-left: 120px; */
}

.alertInfoWrapper .alert {
  height: 35px;
  width: 35px;
}

.alertInfoWrapper .title {
  font-size: 1vw;
  font-weight: 600;
  color: var(--textBlack);
}

.alertInfoWrapper .content {
  font-size: 0.85vw;
  font-weight: 500;
  color: var(--textBlack);
}

.alertInfoWrapper .noButton {
  font-size: 0.85vw;
  font-weight: 600;
  color: var(--font-600);
}
