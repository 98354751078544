/* HeaderDropDown.css */

.drop-down-wrapper {
  width: 100%;
  position: relative;
  outline: none;
  border: none;
  padding: 0 15px;

  /* padding: 23px 8px 30px 7px; */
}

.dropdown-toggle {
  width: clamp(36%, 100px, 36%);
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
}

.selected-brand {
  width: 15vw;
  font-size: 1vw;
  font-weight: 600;
  color: var(--textBlack);
}

.staticHeaderContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  /* gap: 1%; */
}

.dropdown-icon {
  margin-left: 10px;
}

.header-popup {
  width: 17vw;
  position: absolute;
  background: #fff;
  box-shadow: 0px 4px 30px 0px rgba(45, 48, 54, 0.1);
  border-radius: 10px;
}
.create-brand-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px;
}

.create-brand-wrap > span {
  font-size: 0.78vw;
  font-weight: 400;

  color: var(--content);
}

.create-brand-wrap > img {
  width: 20px;
  height: 20px;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
}

.dropdown-menu {
  width: 17vw;
  height: auto;
  max-height: 250px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 30px 0px #2d30361a;
  z-index: 65 !important;
  padding: 0 5px;
}

.dropdown-menu.active {
  display: block;
}

.initial-label-top {
  padding: 5px 12px;
  border-radius: 6px;
  display: flex;
  font-size: 0.92vw;
  font-weight: 450;
  color: var(--black);
  background-color: var(--layoutBorder);
  align-items: center;
  margin-right: 10px;
}

.initial-label {
  padding: 6px 12px;
  border-radius: 6px;
  display: flex;
  font-size: 0.75vw;
  font-weight: 500;
  color: var(--black);
  background-color: var(--layoutBorder);
  align-items: center;
}

.dropdown-item {
  padding: 0px 10px;
  cursor: pointer;
}

.dropdown-item > h4 {
  font-size: 0.8vw;
  font-weight: 500;
  color: var(--dashboardname);
}

.menu-wrapper {
  width: 98%;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  justify-content: space-between;
  z-index: 60;
}

.item-wrapper {
  display: flex;
  align-items: center;
}

.plan-wrap {
  width: 100%;
}

.plan-wrap > span {
  font-size: 0.65vw;
  font-weight: 400;
  color: var(--font-400);
}

.plan-wrap > span:nth-child(2) {
  margin-left: 10px;
}

.avatarContainer {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #000;
  border-radius: 50%;
  position: relative;
}

.avatarImg {
  /* width: 90%;
  height: 90%; */
  width: 30px !important;
  height: 30px !important;
  margin: auto;
}
.childImage {
  width: 18px;
  height: 18px;
  position: absolute;
  border-radius: 50%;
  bottom: -4px;
  right: -4px;
}

.whatsappStatusAvatarBorder {
  border-color: var(--primary);
}
.facebookStatusAvatarBorder {
  border-color: #0064e1;
}

.pinterestStatusAvatarBorder {
  border-color: #df0022;
}
.linkedinStatusAvatarBorder {
  border-color: #0b86ca;
}

.whatsappStatus {
  background: #f0fdf4;
  color: var(--primary);
}

.facebookStatus {
  background: rgba(0, 100, 225, 0.1);
  color: #0064e1;
}

.linkedinStatus {
  background: #e7f3fa;
  color: #0b86ca;
}
.pinterestStatus {
  background: #fce6e9;
  color: #df0022;
}

.secondaryTextContainer {
  display: flex;
  gap: 2%;
  margin-left: 1%;
}

.secondaryTextContainer .secondaryText {
  font-size: 0.7rem !important;
  color: var(--font-600);
  font-weight: 600;
  text-transform: capitalize;
}

.smallerWidth {
  width: 12vw;
}

.smallerWidth .menu-wrapper {
  display: flex;
  align-items: center;
  padding: 1rem 0.8rem;
  border-bottom: 1px solid var(--border-100);
  cursor: pointer;
}

.smallerWidth .item-wrapper {
  font-size: 0.77rem;
  font-weight: 600;
}

.copyBtnContainer {
  /* width: 94.5%; */
  /* margin: 0.5% auto 0; */
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.copyBtnContainer > span {
  font-size: 0.74vw;
  font-weight: 400;
  color: var(--font-400);
  /* padding: 10px 0px; */
}

.ellipsis-text {
  white-space: nowrap;
  width: 9vw;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.9vw;
  font-weight: 600;
  color: var(--textBlack);
}

/* New Header clamp */
