:root {
  --base-color-crm: #616874;
}

div.contacts > *:first-child {
  min-width: calc(13vw + 1vw);
}

div.contacts > * {
  /* max-width: 10vw;
  min-width: 10vw; */
  width: 10vw;
  margin-right: 2.5vw;
}

.scroll_visible:hover::-webkit-scrollbar-thumb {
  background: var(--BG-200) !important;
  border-radius: 50px;
}

.scroll_visible::-webkit-scrollbar {
  display: initial !important;
  width: 0.3vw !important;
  height: 0.2vw !important;
  background: transparent !important;
}

/* only for box shadow. used by multiple components  */
.crm-dropdown {
  /* box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 15px; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

/* shadow for the first column while scroll */
.scroll-shadow {
  box-shadow: 5px 0px 5px -4px rgba(33, 35, 38, 0.15);
}

.custom-shadow-1 {
  box-shadow:
    rgba(141, 141, 141, 0.1) 0px 0px 5px 0px,
    rgba(136, 136, 136, 0.1) 0px 0px 1px 0px;
}

/* style antd radio button used in columns selection */
:is(div.contact-select, div.opt_select, div.crm_filter) .ant-checkbox-inner {
  border-radius: 50% !important;
  overflow: hidden !important;
  width: 16px !important;
  height: 16px !important;
}
/* :is(div.contact-select, div.opt_select, div.crm_filter)
  :where(.css-dev-only-do-not-override-1r287do).ant-checkbox
  .ant-checkbox-inner {
  } */

.loader-shadow {
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  box-shadow:
    rgba(0, 0, 0, 0.196) 0px 1px 4px,
    rgba(0, 0, 0, 0.115) 0px -2px 1px inset;
}

.ani {
  /* left: 70%; */
  animation-name: line_load_crm;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  /* animation-timing-function: ease-in-out; */
  animation-timing-function: cubic-bezier(0.63, 0.04, 0.39, 0.96);
  /* animation-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); */
}

@keyframes line_load_crm {
  0% {
    left: -100%;
  }
  50% {
    left: 0;
  }
  100% {
    left: 100%;
  }
}

.file-upload {
  position: relative;
  overflow: hidden;
  /* width: 20%; */
  height: 100px;
  /* background: #6da047; */
  border-radius: 3px;
  padding: 8px 4px;
  color: #fff;
  text-align: center;
}
.file-upload:hover {
  /* background: #7aad55; */
}
.file-upload input[type='file'] {
  display: none;
}
.file-upload label {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.file-upload span {
  line-height: 36px;
}

.ttest {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23CECECEFF' stroke-width='4' stroke-dasharray='2%2c6' stroke-dashoffset='3' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 10px;
}

/* crm file upload loader animations */

.progress {
  --progress: 0%;
}

.progress .bar {
  width: var(--progress);
  transition: width 3s ease;
}

/* singleContact details info page input style */
.contact-edit-cont {
  transform-origin: all 1s;
  border-color: transparent;
}
.contact-edit-cont > .input-editIcon {
  transform: scale(0);
  opacity: 0;
}
.contact-edit-cont:hover {
  border-color: #61687427;
  padding-left: 0.5vw;
}
.contact-edit-cont:hover > .input-editIcon {
  transform: scale(1);
  opacity: 100%;
}

.opt-out button.ant-switch {
  /* background-color: green; */
}

.opt-out button.ant-switch:hover {
  background-color: #f96056;
}

.opt-out button.ant-switch {
  background-color: #f96056;
}

.opt-out
  :where(
    .css-dev-only-do-not-override-1r287do
  ).ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background-color: #0acd95;
}
.opt-out
  :where(.css-dev-only-do-not-override-1r287do).ant-switch.ant-switch-checked {
  background-color: #0acd95;
}
