.disable-button-contact {
  padding: 6px 10px;
  background-color: var(--lightGrey);
  border-radius: 6px;
  font-size: 0.75vw;
  font-weight: 500;
  color: var(--textBlack);
  pointer-events: auto !important;
  cursor: not-allowed !important;
}

.country-popover {
  height: auto;
  max-height: 25vh;
  overflow: scroll;
}

.country-popover > div {
  width: 20vw;
  border-bottom: 1px solid var(--border-50);
}

.country-popover > div:first-child {
  border-bottom: none;
}

.country-popover > div:last-child {
  border-bottom: none;
}

.country-popover > div:not(:first-child):hover {
  background-color: var(--channelButton);
  padding: 10px 10px;
  border-radius: 6px;
}
.country-popover > div:hover {
  background-color: var(--channelButton);
  padding: 10px 10px;
  border-radius: 6px;
}

.country-popover > .state:hover {
  background-color: none;
  padding: 0;
}

.country-popover1 {
  height: auto;
  max-height: 25vh;
  overflow: scroll;
}

.country-popover1 > div {
  width: 20vw;
  border-bottom: 1px solid var(--border-50);
}

.country-popover1 > div:first-child {
  border-bottom: none;
}

.country-popover1 > div:last-child {
  border-bottom: none;
}

.country-popover1 > div:hover {
  background-color: var(--channelButton);
  padding: 20px 10px;
  border-radius: 6px;
}

.country-popover1 > .state:hover {
  background-color: none;
  padding: 0;
}

/* CRM TABLE */
.crm-table-list {
  position: relative;
  display: flex;
  align-items: center;
}

.crm-table-list .checkBoxContainer {
  position: absolute;
  left: 0;
  opacity: 0;
  transition:
    opacity 0.1s ease,
    transform 0.1s ease;
}

/* .crm-table-list:hover .checkBoxContainer {
  opacity: 1;
} */

/* .crm-table-list:hover > div {
  transform: translateX(20px);
  transition: transform 0.1s ease;
} */

.tags-hover-label:hover > span > span {
  background-color: red;
}

.tags-border {
  border-bottom: 1px solid var(--border-50);
}

.edit-tag-input {
  border: 1px solid #74caa9;
  /* width: 50%; */
}
