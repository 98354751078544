.ToastWrapper {
  position: absolute;
  top: 50px;
  right: 20px;
  z-index: 999 !important;
}

.toast-container {
  width: 400px;
  background: var(--background);
  box-shadow: 0px 4px 30px 0px rgba(45, 48, 54, 0.1);
  color: var(----black);
  height: auto;
  min-height: 7vh;
  padding: 10px 10px;
  margin-bottom: 20px;
  border-radius: 10px;

  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes fadeOut {
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

.toast-container .toast-content {
  font-size: 0.75vw;
  font-weight: 700;
  color: var(--font-600);
}
.toast-color {
  width: 4px;
  height: 40px;
  border-radius: 4px;
  margin-right: 10px;
}
.toast-container > div > img {
  width: 25px;
  height: 25px;
  margin-right: 20px;
}
.icon {
  display: flex;
  justify-content: flex-end;
}

/* Define CSS classes for different message types */
/* .success {
  background-color: green;
  color: white;
}

.error {
  background-color: red;
  color: white;
}

.info {
  background-color: blue;
  color: white;
} */
