.google-card {
  width: 100%;
  height: auto;
  border: 1px solid var(--card-border);
  /* background-color: var(--background); */
  background: var(--BG-100);
  color: var(--font-600);
  opacity: 0.7;
  border-radius: 10px;
  padding: 10px 10px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: not-allowed;
}
.google-card > img {
  width: 15px;
  height: 15px;
}
.google-card > p {
  font-size: 0.75vw;
  font-weight: 500;
  color: var(--textblack);
  margin-left: 10px !important;
  margin: 0px;
}
