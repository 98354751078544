/* .contact-details-popover {
  width: 25%;
  top: 150px !important;
  transform: translate(-10vw) !important;
} */

.contact-details-popover {
  width: 23%;
  top: 150px !important;
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .contact-details-popover {
  right: 27vw !important;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .contact-details-popover {
  right: 27vw !important;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .contact-details-popover {
  right: 27vw !important;

  }
}

@media (min-width: 1200px) {
  .contact-details-popover {
  right: 27.5vw !important;

  }
}
