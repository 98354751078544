.storybook-button {
  font-weight: 600;
  border: 0;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.02rem;
}

.storybook-button--primary {
  color: var(--white);
  background-color: var(--primary) !important;
}

.storybook-button--secondary {
  color: var(--font-50);
  background-color: var(--primary) !important;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px inset;
}

.storybook-button--small {
  font-size: 0.75vw;
  padding: 5px 10px;
  border-radius: 6px;
}

.storybook-button--medium {
  font-size: 0.8vw;
  padding: 6px 8px;
  border-radius: 6px;
}

.storybook-button--large {
  font-size: 0.8vw;
  padding: 9px 22px;
}

.storybook-button--xm {
  /* width: 4%; */
  font-size: 0.65vw;
  padding: 5px 6px;
  border-radius: 4px;
}

.storybook-button--xs {
  width: 4%;
  font-size: 0.3vw;
  padding: 5px 0;
  border-radius: 4px;
  margin-top: 20px;
}

.storybook-button--xl {
  width: 12%;
  font-size: 0.73vw;
  font-weight: 400;
  padding: 3px 5px;
  border-radius: 4px;
  /* margin-top: 20px; */
}

.storybook-button--xxm {
  width: 3.8%;
  font-size: 0.7vw;
  padding: 5px 0px;
  border-radius: 4px;
}

.storybook-button > .leftImage {
  width: 18px;
  height: 18px;
  margin: 0 6px 0 0px !important;
}

.storybook-button > .rightImage1 {
  width: 18px;
  height: 18px;
  /* margin: 0 10px 0 10px; */
}

.storybook-button > .rightImage2 {
  width: 15px;
  height: 15px;
  margin: 0 0px 0 5px;
}

.secondVariant {
  font-size: 0.75vw;
  padding: 6px 8px;
  border-radius: 6px;
  font-weight: 400;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.02rem;
  color: var(--white) !important;
  background-color: var(--secondary) !important;
}
