.campaign-view {
  font-size: 22px;
  font-weight: 700;
  color: var(--textBlack);
}

.campaign-filters {
  font-size: 0.875rem;
  font-weight: 400;
  color: var(--textBlack);
}

.campaign-no-data {
  /* height: 50vh; */
  background: var(--white);
  border-radius: 16px;
  transform: translateY(30px);
}
