.tableWrapper1 {
  width: 100%;
  height: 100%;
  max-height: 55vh !important;
  overflow-y: scroll !important;
}

.tableWrapper1 table {
  border-collapse: separate !important;
  border-spacing: 0 10px !important;
  overflow-y: scroll;
  position: relative;
}

.addIcon {
  position: absolute;
  top: 10px;
  right: 5px;
  z-index: 1000 !important;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.tableHeaderRow1 {
  position: sticky;
  top: 0;
  z-index: 20 !important;
  width: 100%;
}

.tableWrapper1 th {
  width: 150px;
  height: 40px;
  font-size: 0.835vw !important;
  font-weight: 600;
  background: var(--BG-25);
  color: var(--textBlack);
}

.listRow1 {
  width: 500px;
  background: var(--background);
  height: 80px;
  cursor: pointer;
  padding: 0px 10px !important;
}
.status-complete {
  font-size: 0.75vw;
  font-weight: 500;
  color: var(--textBlack);
}
.tableWrapper1 td:first-child {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}
.tableWrapper1 td:last-child {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.rowBar {
  position: absolute;
  top: 10px;
  left: 0;
  bottom: 10px;
  width: 4px;
  height: 50px;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.firstColumnWrapper {
  height: 50px;
}
.tableWrapper1 td {
  font-size: 0.8vw !important;
  font-weight: 500;
  color: var(--textBlack);
  vertical-align: middle !important;
  text-align: center;
  margin: auto;
}
.tableWrapper1 td:nth-child(1) {
  padding: 10px 10px 10px 10px;
  text-align: left;
  width: 20.833vw !important;
  position: relative;
  position: sticky;
  left: 0px;
  z-index: 1;
  background-color: var(--background);
}
.tableWrapper1 th:nth-child(1) {
  width: 400px !important;
  min-width: 400px !important;
  /* text-align: left; */
  padding-left: 10px;
  z-index: 30 !important;
  padding-top: 10px;
  position: sticky;
  left: 0px;
  background: var(--BG-25);
  display: flex;
  flex-direction: row;
}

.custom-skeleton {
  width: 1000vw;
  margin-top: 20px;
}

/*  */

.broadcast-icons1 {
  /* height: 10px; */
}

.listRow1:hover .broadcast-icons1 {
  display: block;
}

.broadcast-icons1 {
  /* display: none; */
}

.broadcast-icons1 > img {
  width: 25px;
  margin-left: 10px;
  background-color: transparent;
  border-radius: 6px;
  padding: 5px 2px;
}

.broadcast-icons1 > img:hover {
  background-color: var(--channelButton);
}

/* AudienceReport */

.Audience_wrapper {
  padding: 20px;
}

.Audience_wrapper > div > span {
  font-size: 0.95vw;
  font-weight: 600;
}

.Audience_table {
  background: #f0f1f2;

  height: 100%;
  max-height: 50vh;
  overflow: scroll;
  padding: 10px 10px;
  border-radius: 5px;
  /* overflow: hidden; */
}
.Audience_right > span {
  font-size: 0.85vw;
  font-weight: 500;
}
.Audience_left > div > span {
  font-size: 0.85vw;
  font-weight: 500;
}
.Audience_list_main {
  /* width: auto; */
  /* width: 1100px; */
  width: 87.292vw;
  overflow: auto;
  background: var(--background);
  height: 80%;
  /* overflow: scroll; */
  border-radius: 10px;
  /* padding: 10px 20px 20px 0px; */
  margin: 10px 0px;
}

.Audience_list_main > div > div > span {
  padding: 10px 10px;
  font-size: 0.85vw;
  font-weight: 600;
}
.Audience_list_main > div > div > img {
  width: 16px;
  height: 16px;
}
.main > div > span {
  padding: 10px 10px;
  font-size: 0.85vw;
  font-weight: 500;
}

.segment_list {
  background: #f0f1f2;
  border-radius: 10px;
  padding: 10px 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
}
.segment_list > div {
}
.segment_list > div > div {
  background: var(--background);
  height: 7vh;
  border-radius: 10px;
  padding: 5px 5px;
  margin: 5px;
}

.audi {
  transform: translate(6px);
}

.segment_list > div > div > div > div > span {
  font-size: 0.75vw;
  font-weight: 500;
  padding: 4px 5px;
  color: var(--textBlack);
}
.Right-sight {
  text-align: center;
}

.delay {
  transform: translate(-20px);
}

.delivered {
  transform: translate(20px);
}
.opened {
  transform: translate(45px);
}
.clicks {
  transform: translate(70px);
}
.leads {
  transform: translate(70px);
}
.Right-sight > span {
  font-size: 0.85vw;
  font-weight: 500;
}

.segment_column > span {
  color: var(--channelToggleSwitch);
  font-size: 0.65vw;
  font-weight: 400;
}

.segment_name {
  font-size: 0.85vw;
  font-weight: 600;
  padding: 0px 10px;
}
.header_name {
  font-size: 0.85vw;
  font-weight: 600;
}
