/* Contact_details */

.contactView_container {
  height: auto;
  max-height: 82vh;
  overflow-x: hidden;
}
.contact_wrapper {
  padding: 10px 10px;
}
.contact_wrapper > div {
  border: 1px solid var(--channelButton);
  width: 200px !important;
  padding: 7px 10px;
  border-radius: 6px;
}
.contact_wrapper > div > p {
  font-size: 0.85vw;
  font-weight: 500;
  color: var(--channelToggleSwitch);
}
.filter-wrapper-main_A {
  min-width: 24vh;
  max-width: auto;
  height: auto;
  padding: 10px 7px;
  border-radius: 7px;
  background: #fff;
  box-shadow: 0px 4px 30px 0px rgba(45, 48, 54, 0.1);
}
.filter_wrapper {
  padding: 10px 7px;
  font-size: 0.85vw;
  font-weight: 500;
}
.filter-wrapper-main_A > div {
  padding: 10px 7px;
}

.filter-wrapper-main_A > div > p {
  font-size: 0.75vw;
  font-weight: 500;
}

.filter-dropdown {
  width: 20vh;
  height: auto;
  border-radius: 7px;
  background: #fff;
  box-shadow: 0px 4px 30px 0px rgba(45, 48, 54, 0.1);
}

.search_container {
  width: 17vw;
  padding: 10px 10px;
}
.contact_table {
  background: #f0f1f2;
  margin-left: 10px;
  /* height: auto; */
  min-height: 57.5vh;
  padding: 10px 0px;
  border-radius: 5px;
}
.header_names {
  width: 15vw;
  font-size: 0.85vw;
  font-weight: 500;
}

.contact_table_wrapper {
  /* width: 73.5vw; */
  background-color: var(--background);
  height: 7vh;
  margin: 8px;
  padding: 0px 15px;
}
.contact_data {
  height: 100%;
  max-height: 60vh;
  overflow-x: hidden;
}
.header_right {
  font-size: 0.75vw;
  font-weight: 600;
  color: #2d3036;
}

.contact_left {
  font-size: 0.75vw;
  font-weight: 400;
}

.contact_left_top {
  font-size: 0.85vw;
  font-weight: 500;
}

.contact-segment-img {
  width: 0.7vw;
}

.contact-segment > p {
  font-size: 0.75vw;
  font-weight: 400;
}
.select2 {
  width: 4vw !important;
  border: 1px solid var(--card-border);
  border-radius: 4px;
}

.select2 > div {
  padding: 5px 5px;
}

.page-popover {
  width: 6vw;
  padding: 5px;
}

.page-popover > div {
  padding: 10px;
}

.page-popover > div > p {
  font-size: 0.75vw;
}

.page-tag {
  font-size: 0.75vw;
}

:where(.css-dev-only-do-not-override-1ae8k9u).ant-pagination
  .ant-pagination-item
  a {
  color: #2d3036;
  font-size: 0.729vw;
}

:where(.css-dev-only-do-not-override-1ae8k9u).ant-pagination
  .ant-pagination-item-active
  a {
  background-color: var(--primary);
  color: white !important;
  font-weight: 400;
  border-radius: 4px;
}

:where(.css-dev-only-do-not-override-1ae8k9u).ant-pagination
  .ant-pagination-item-active {
  border-color: transparent !important;
}

/* .custom-size-changer {
  width: 8vw !important;
} */

.custom-pagination > div > p {
  color: #616874;
  font-size: 0.729vw;
  padding-right: 20px;
  font-weight: 400;
}

:where(.css-dev-only-do-not-override-1ae8k9u).ant-pagination
  .ant-pagination-options {
  margin-inline-start: 0px;
}

.quick-jumper > span {
  color: #2d3036;
  font-size: 0.729vw;
  font-weight: 400;
  margin-inline-start: 0px;
}

.no-arrows {
  border-radius: 4px;
  width: 4vw;
  margin-inline-start: 10px;
  border: 1px solid var(--card-border);
  padding: 5px;
  font-size: 0.75vw;
  font-weight: 400;
  text-align: center;
}

.no-arrows::-webkit-inner-spin-button,
.no-arrows::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ellipsis-texts {
  white-space: nowrap;
  width: 10vw;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.9vw;
  font-weight: 600;
  color: var(--textBlack);
}

.download_disabled {
  opacity: 0.4;
}
