.tableWrapper {
  width: 100%;
  height: 100%;
  height: 95vh !important;
  overflow-y: scroll;
}

.tableWrapper table {
  border-collapse: separate !important;
  border-spacing: 0 10px !important;
  overflow: scroll;
}

.addIcon {
  position: absolute;
  top: 10px;
  right: 5px;
  z-index: 1000 !important;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.tableHeaderRow {
  position: sticky;
  top: 0;
  z-index: 20 !important;
  width: 100%;
}

.tableWrapper th {
  width: 150px;
  height: 40px;
  font-size: 0.835vw !important;
  font-weight: 600;
  background: var(--BG-25);
  color: var(--textBlack);
}

.listRow {
  width: 500px;
  background: var(--background);
  height: 80px;
  cursor: pointer;
  padding: 0px 10px !important;
}
.status-complete {
  font-size: 0.75vw;
  font-weight: 500;
  color: var(--textBlack);
}
.tableWrapper td:first-child {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}
.tableWrapper td:last-child {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.rowBar {
  position: absolute;
  top: 16px !important;
  left: 0;
  bottom: 10px;
  width: 4px;
  height: 50px;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.firstColumnWrapper {
  height: 50px;
}
.tableWrapper td {
  font-size: 0.65vw !important;
  font-weight: 400;
  color: var(--textBlack);
  vertical-align: middle !important;
  text-align: center;
  margin: auto;
  /* border: 1px solid green !important; */
  /* width: calc(100% / 4); */
}
.tableWrapper td:nth-child(1) {
  padding: 10px 10px 10px 10px;
  text-align: left;
  /* width: 2vw !important; */
  position: relative;
  z-index: 0;
  position: sticky;
  left: 0;
  background-color: var(--background);
}
.tableWrapper th:nth-child(1) {
  width: 26vw;
  padding-left: 10px;
  z-index: 30 !important;
  padding-top: 10px;
  position: sticky;
  left: 0;
  background: var(--channelButton);
  display: flex;
  flex-direction: row;
}

.custom-skeleton {
  width: 1000vw;
  margin-top: 20px;
}

/*  */

.messageTable-icons {
  height: 0px;
  margin-bottom: 20px !important;
}

.listRow:hover .messageTable-icons {
  display: block;
}

.messageTable-icons {
  display: none;
}

.edit-icon > img {
  width: 30px;
  margin-left: 10px;
  background-color: transparent;
  border-radius: 6px;
  padding: 5px 2px;
  margin-top: 2px;
}

.edit-icon > img:hover {
  background-color: var(--channelButton);
}
