.usagePlan {
  width: 24vw;
}

.usagePlan-popup {
  border-bottom: 1px solid var(--authDivider);
  padding: 8px 20px;
}

.usage-popup-exit {
  width: 20px;
  height: 20px;
}

.usagePlan-popup > h2 {
  font-size: 0.938vw;
  font-weight: 600;
}

.usagePlan-container {
  padding: 12px 20px;
}
.growth-plan-whatsapp {
  border-bottom: 1px solid var(--font-100);
}
.growth-plan-whatsapp > p {
  font-size: 0.833vw;
  font-weight: 500;
}
.growth-plan-whatsapp > div {
  width: 65%;
  padding: 0px 0px 10px 0px;
}
.growth-plan-whatsapp > div > p {
  color: var(--secondary);
  font-size: 1.25vw;
  font-weight: 700;
}
.growth-plan-whatsapp > div > span {
  color: var(--font-400);
  font-size: 0.75vw;
  font-weight: 500;
}

.plan-renews {
  font-size: 0.625vw;
  color: #898e99;
  font-weight: 400;
}

.plan-credits {
  font-size: 0.729vw;
  font-weight: 500;
  color: #6940f2;
}

.plan-sliders {
  width: 100%;
  height: 1px;
  border-radius: 40px;
  background: #fff;
  overflow: hidden;
  margin-top: 2px;
}

.plan-animation:hover .plan-slides {
  width: 100%;
  height: 100%;
  border-radius: 40px;
  background: linear-gradient(to right, #6940f2 100%, transparent 0%);
  animation: Animationslide 0.5s ease-in-out forwards;
}

@keyframes Animationslide {
  to {
    background-position:
      -100% 0,
      -100% 0;
  }
  0% {
    transform: translateX(-100%);
    background: linear-gradient(to right, #6940f2 100%, transparent 0%);
  }
  100% {
    transform: translateX(0%);
    background: linear-gradient(to right, #6940f2 100%, transparent 0%);
  }
}

.plan-button {
  height: 20px;
  font-size: 0.625vw;
  border-radius: 4px;
  padding: 12px;
  color: #fff;
  /* background: linear-gradient(90deg, #6940f2 -30.87%, #6940f2 213.93%); */
  background: linear-gradient(90deg, #0acd95 -30.87%, #6940f2 213.93%);

  font-weight: 600;
  display: flex;
  align-items: center;
}

.upgrade-arrow {
  width: 9px;
  height: 9px;
  margin-left: 10px;
}

.whatsapp-credits {
  padding-top: 20px;
}

.plan-title {
  font-size: 0.729vw;
  color: #2d3036;
}

.plan-remaining-credits {
  font-size: 0.729vw;
  font-weight: 600;
  padding-left: 3px;
}

.plan-remaining-credits > span {
  font-weight: 400;
  color: #898e99;
}

.upgrade-plan-button {
  background: linear-gradient(90deg, #0acd95 -188.33%, #6940f2 100.36%);
  color: #fff;
  font-weight: 600;
  font-size: 0.729vw;
  padding: 5px 20px;
  border-radius: 6px;
}
