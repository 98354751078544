.custom-checkbox input[type='checkbox'] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;
  font: inherit;
  width: 100%;
  height: 100%;
  color: currentColor;
  /* color: currentColor; */
  border: 1.5px solid #898e99;
  /* transform: translateY(-0.075em); */
  display: grid;
  place-content: center;
  cursor: pointer;
}

.circle {
  border-radius: 50%;
}

.squre {
  border-radius: 3px !important;
}

.custom-checkbox input[type='checkbox']::before {
  content: '';
  width: 8px;
  height: 8px;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 0.5em 0.5em white;
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

/* .custom-checkbox input[type='checkbox']::before.circle {
  transition: 120ms transform ease-in-out;
  box-shadow: inset 0.5em 0.5em white;
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

.custom-checkbox input[type='checkbox']::before.squre {
  transition: 120ms transform ease-in-out;
  box-shadow: inset 0.5em 0.5em white;
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
} */

.custom-checkbox input[type='checkbox']:checked::before {
  transform: scale(1);
}

.custom-checkbox input[type='checkbox']:checked {
  background-color: var(--new-primary);
  border: none;
}
