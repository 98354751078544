.create-wrapper {
    padding: 10px 18px;
    border-radius: 10px;
    background: var(--background);
    z-index: 50 !important;
    box-shadow: 0px 4px 30px 0px rgba(45, 48, 54, 0.1);
    margin-bottom: 5px;
  }
  .createPopup {
    width: 100% !important;
  }
  
  .create-list {
    gap: 5px;
    cursor: pointer;
    padding: 10px 0px;
  }
  
  .create-list img {
    width: 18px;
    height: 18px;
  }
  
  .create-list > span {
    font-size: 0.88vw;
    font-weight: 400;
    color: var(--textBlack);
  }
  
  .custom-modal-popup-campaign {
    width: 60% !important;
    height: auto !important;
  }
  
  .popup-steps {
    width: 40%;
    padding-left: 40px;
  }
  
  .popup-wrap-campaign {
    width: 60%;
    display: flex;
    flex-direction: column;
    padding: 20px 0;
  }
  
  .popup-wrap-campaign-middle {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
  }
  .popup-wrap-campaign-middle > span {
    margin-top: 10px;
  }
  .popup-wrap-campaign-list {
    display: flex;
    margin-top: 5px;
  }
  .campaign-popup-footer {
    width: 90%;
    display: flex;
    justify-content: flex-end;
    padding: 0 0px 40px 0;
  }