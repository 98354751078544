.authWrapper {
  height: 100vh;
  width: 100vw;
  background-color: var(--background);
  display: flex;
  align-items: center;
  justify-content: center;
}
.leftLayout {
  width: 55.5vw;
  height: 100%;
  border-radius: 0px 40px 40px 0px;
  overflow: hidden;
  position: relative;
}
.leftLayoutImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.rightsText {
  position: absolute;
  bottom: 30px;
  left: 45px;
  font-size: 0.7vw;
  font-weight: 500;
  letter-spacing: 0.2px;
  color: var(--textBlack);
}

.rightLayout {
  width: 44.5vw;
  height: 100%;
  overflow-y: scroll;
}
/* .back {
  display: flex;
  flex-direction: row;
} */
.back {
  display: flex;
  width: 150px;
  height: 70px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.backText {
  font-size: 0.82vw;
  font-weight: 500;
  color: var(--darkgrey);
  margin-left: 7px;
}
.back > img {
  width: 10px;
  height: 10px;
}

.header {
  height: 28%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.body {
  height: auto;
  min-height: 65%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer {
  height: 6%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
}
.authDivider {
  background-color: var(--authDivider);
  height: 1px;
}

/* signin */

.step1Sentence {
  margin-top: 30px !important;
  color: var(--textBlack);
  font-weight: 700;
  font-size: 0.9vw;
  margin: 0;
}

.login {
  color: var(--textBlack);
  font-size: 0.82vw;
  font-weight: 400;
  margin-top: 20px;
}

.navigation {
  text-decoration: none;
  color: var(--a);
  font-size: 0.8vw;
  font-weight: 600;
  margin-left: 5px;
  cursor: pointer;
}

/* Accounts */

.account-card-main-container {
  height: 100% !important;
  max-height: 40vh;
  overflow: scroll;
  padding: 20px 20px;
}

.account-card-g {
  height: 100% !important;
  margin-top: 20px;
  padding: 10px 20px;
}

.account-card-g:first-child {
  margin-top: 0;
}

.account-initial {
  background: var(--channelButton);
  /* padding: 10px 20px; */
  border-radius: 6px;
  font-size: 1.25vw;
  font-weight: 600;
  color: var(--textBlack);
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.account-card-g:hover {
  background-color: white;
  box-shadow: 0px 4px 30px 0px #e9e9e9;
  border-radius: 10px;
  /* padding: 10px 20px; */
}

.account-card-g > div > img {
  width: 38px;
}

.account-card-titles-g > span {
  font-size: 0.85vw;
  font-weight: 700;
  color: var(--textBlack);
  letter-spacing: 0.2px;
  /* padding-bottom: 5px; */
}

.account-card-content-g > img {
  width: 13px;
  margin-right: 5px;
}

.account-card-content-g > span {
  font-size: 0.68vw;
  font-weight: 500;
  color: var(--textBlack);
  letter-spacing: 0.2px;
}

.account-card-g > img:nth-child(1) {
  width: 10px;
}

.account-card-g > img:nth-child(3) {
  display: none;
  width: 12px;
}

.account-card-g:hover > img:nth-child(2) {
  display: none;
}

.account-card-g:hover > img:nth-child(3) {
  display: block;
}
