.selectWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}
.selectInputWrapper {
  height: 100%;
  display: flex;
  /* height: 40px !important; */
  flex-direction: row;
  border-radius: 6px;
  border: 1px solid var(--card-border);
  justify-content: space-between;
  align-items: center;
}
.selectInputWrapper .leftImage {
  width: 17px;
  height: 17px;
}
.selectInput {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 0.75vw;
  font-weight: 500;
  border-radius: 6px;
  background-color: var(--background);
  padding: 6px 10px;
  display: flex;
  justify-content: space-between;
}

.selectOptionWrapper {
  position: absolute;
  width: 100% !important;
  height: auto;
  max-height: 300px;
  overflow-y: auto;
  border-radius: 6px;
  background: var(--background);
  box-shadow: -10px 0px 30px 0px rgba(0, 0, 0, 0.1);
  padding: 10px 10px;
  margin-top: 5px;
  cursor: pointer;
  z-index: 200 !important;
}
.selectOptions:hover {
  background: var(--channelButton);
  border-radius: 6px;
}
.selectOptions {
  padding: 12px 15px 12px 5px;
  border-top: 1px solid var(--border-50);
  color: var(--textBlack);
  font-size: 0.75vw;
  font-weight: 500;
  letter-spacing: 0.2px;
}

.selectOptions:nth-child(2) {
  border-bottom: none;
}

.selectOptions:nth-child(1) {
  border-top: none;
}

.selectOptionWrapper .optionsDisabled {
  color: var(--font-200);
  cursor: not-allowed;
}
.selectedOption {
  /* border: 0.15rem solid var(--primary); */
}
.optionIcon {
  width: 18px;
  height: 18px;
  margin-right: 6px;
}
