.brand-settings-main-container {
  width: 100%;
  height: 100%;
  overflow-x: scroll;
  padding: 20px 15px;
  gap: 2%;
  background-color: var(--background);
}

.brand-setting-wrapper {
  width: 100%;
}
/*   brand-details-main-container   ----------------------------------------------------- */

.brand-details-main-container {
  width: 49%;
  box-shadow: 0px 1.5px 2px 0px var(--channelBoxShadow);
  padding: 10px 10px;
  border-radius: 10px;
  border: 1px solid var(--font-50);
}

.brand-upload-image {
  position: relative;
}

.brand-upload-image > .brand-uploaded-image {
  width: 95%;
  border-radius: 10px;
  object-fit: cover;
}

.brand-popup-exit {
  position: absolute;
  right: 10px;
  width: 20px;
}

.custom-file-upload1 {
  width: 30%;
  position: relative;
  height: 6.5vw;
  border-radius: 6px;
  margin-right: 10px;
}

.custom-file-upload1 > input[type='file'] {
  display: none;
}

.custom-file-upload1 {
  width: 11.5vw;
  height: 20vh;
  border: 1px solid var(--channelButton);
  background-color: var(--channelButton);
  /* background: linear-gradient(315deg, var(--borderPrimary) 1.93%, var(--borderGreen) 100%); */
}
.custom-file-upload1 > img {
  /* width: 20%; */
}

.custom-file-upload1 > p {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: var(--white);
  font-size: 0.83vw;
  font-weight: 600;
}

/*   Subscription plan   ----------------------------------------------------- */
.subscription-container {
  width: 100%;
  padding: 10px 0px;
}
.subscription-container > span {
  font-size: 0.94vw;
  font-weight: 600;
}
/* .subscription-container > div > p {
  color: var(--a);
  font-size: 0.78vw;
  font-weight: 500;
  padding-left: 10px;
  
} */
.subscription-wrapper {
  border-radius: 16px;
  width: 100%;
  /* background: var(--channelButton); */
}
.subscription-wrapper > div {
  padding: 5px 5px;
}
.subscription-wrapper > div > .staterPlan-container > img {
  width: 2.7vw;
  /* height: 7vh; */
}
.staterPlan-container > div > div {
  padding-top: 8px;
  padding-left: 7px;
}

.staterPlan-container > div > div > p {
  font-size: 18px;
  font-weight: 600;
  padding-right: 10px;
}
.staterPlan-container > div > div > img {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.plan_name {
  padding: 3px 4px;
  border-radius: 5px;
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: var(--secondary);
  color: var(--background);
  margin: 5px;
}
.plan_name > p {
  font-size: 0.74vw;
  font-weight: 500;
}
.plan_content > div {
  width: 100%;
  display: flex;
}
.plan_content > div > span {
  font-size: 0.68vw;
  font-weight: 500;
  color: var(--BG-600);
  padding: 5px 10px 0px 10px;
}
.plan_content > img {
  width: 30px;
  height: 30px;
}
.plan_content > div > p {
  font-size: 0.833vw;
  font-weight: 600;
  padding: 0px 10px;
}

.staterPlan-Left > div > span {
  font-size: 1.25vw;
  font-weight: 700;
}
.staterPlan-Left > p {
  color: var(--font-400);
  font-size: 0.75vw;
  font-weight: 500;
  padding-left: 30px;
}
.brand-button > div > .btn {
  padding-left: 15px;
  color: var(--channelToggleSwitch);
  font-size: 0.78vw;
  font-weight: 600;
}
.brand-button > p {
  color: var(--channelToggleSwitch);
  font-size: 0.74vw;
  font-weight: 600;
}
.Link_content {
  font-size: 0.74vw;
  font-weight: 600;
  color: var(--BrandLink);
  padding-left: 5px;
}
.Update_payment {
  font-size: 0.74vw;
  font-weight: 600;
  color: var(--BrandLink);
  padding-left: 5px;
}

.plan_content {
  padding: 15px 0px;
}
.Plan_credit > span {
  font-size: 0.833vw;
  font-weight: 500;
  color: var(--channelToggleSwitch);

  padding: 6px 0px;
}

/*   Billing information  ----------------------------------------------------- */
.Billing-container {
  width: 49%;
}
.Billing-wrapper {
  width: 100%;
}
.billing {
  font-size: 0.938vw;
  font-weight: 600;
}
.Bill-details {
  width: 100%;
  /* height: auto; */
  height: 42vh;
  box-shadow: 0px 1.5px 2px 0px #1018281a;
  padding: 0px 10px;
  margin: 10px 0px;
  border-radius: 10px;
  border: 1px solid var(--font-50);
}
.Bill-details1 {
  padding: 0px 10px;
  margin: 10px 0px;
  border-radius: 10px;
}
.Bill-details1 > .Brand-details {
  width: 49%;
}
.Brand-details {
  padding: 3px 0px;
}
.Brand-details > div {
  padding: 3.5px 0px;
}

/*   Billing History   ----------------------------------------------------- */

.Billing-history-container {
  width: 49%;
}

.Billing-history-container > div {
  width: 100%;
  margin: 0px 0px 10px 0px;
}

.Billing-history-container > div > p {
  font-size: 0.99vw;
  font-weight: 600;
}

.Billing-history-wrapper {
  width: 100%;
  border-radius: 16px;
  height: 43vh;
  margin: 10px 0px 0px 0px;
  padding: 10px 0px;
  background: var(--channelButton);
}
.billing-title {
  width: 100%;
}

.Billing-history-wrapper > div > .invoice {
  width: 30%;
  padding: 0px 15px;
  font-size: 0.95vw;
  font-weight: 500;
}
.Billing-history-wrapper > div > .Billing-subtitle {
  width: 60%;
}
.Billing-subtitle > p {
  font-size: 0.75vw;
  font-weight: 500;
}
.aa {
  width: 100%;
  height: 35vh;
  overflow-y: scroll;
}
.Billing-card {
  margin: 5px 10px 10px 10px;
  height: 6vh;
  border-radius: 10px;
  background: var(--background);
}
.Billing-card > div {
  width: 100%;
}
.Billing-card > div > div {
  width: 40%;
}
.Billing-right > .Billing-icon > div {
  padding: 0px 10px;
}
.Billing-right > .Billing-icon > div > img {
  width: 25px;
  height: 25px;
}
.Billing-right {
  width: 40%;
}
.Billing-right > div {
  padding: 0px 5px;
}

.Billing-right > div > p {
  font-size: 0.82vw;
  font-weight: 500;
}
.Billing-card > div > .Billing-card-left {
  width: 60%;
}
.Billing-card-left > p {
  color: var(--channelToggleSwitch);
  font-size: 0.68vw;
  font-weight: 500;
}
.popover-container {
  /* position: absolute;
  right: -20px;
  top: 30px; */
  border-radius: 10px;
  border: 1px solid var(--BG-50);
  background: var(--textWhite);
}

.popover-container > div > div {
  width: 11vh;
  height: auto;
  display: flex;
  flex-direction: column;

  padding: 10px 5px;
  border-bottom: 1px solid var(--font-50);
}
.popover-container > div > p {
  font-size: 0.76vw;
  font-weight: 500;
  padding: 5px 5px;
  color: var(--channelToggleSwitch);
}
.popover-container > div > div > span {
  font-size: 0.76vw;
  font-weight: 500;

  color: var(--textBlack);
}

/*   Billing Invite   ----------------------------------------------------- */
.BrandInvite-container {
  width: 100;
}
.BrandInvite-container > div {
  padding: 0px 10px 10px 0px;
}
.BrandInvite-container > div > p {
  font-size: 0.88vw;
  font-weight: 600;
}
.BrandInvite-wrapper {
  width: 100%;
}
.Brand-input {
  width: 80%;
  outline: none;
  font-size: 0.74vw;
  font-weight: 400;
  margin-right: 45px;
}
.brand-button > button {
  width: 100%;
  color: var(--primary);
  font-size: 0.78vw;
  font-weight: 600;
}
.brand {
  width: 100%;
  height: auto;
  max-height: 70vh;
  gap: 7px;
}
.Brand-Delete {
  padding: 15px 10px;
}
.Brand-Delete > img {
  width: 20px;
  height: 20px;
}

/* Invite member */
.Invite-container > div {
  padding: 6px 10px;
}

.Invite-container > div > p {
  font-size: 0.95vw;
  font-weight: 600;
}
.Brand-Name {
  width: 40%;
}
.Brand-subtitle {
  width: 60%;
}
.Brand-Name > span {
  font-size: 0.84vw;
  font-weight: 600;
  padding: 0px 20px;
}

.billing-title > div > p {
  font-size: 0.85vw;
  font-weight: 500;
  color: var(--contentText);
}
.Billing-Invite {
  margin: 10px 1px;
  border-radius: 10px;
  background: var(--background);
  position: relative;
}

.Brand-Detail_card {
  width: 40%;
  padding: 10px;
}
.Brand-Detail_card > div > div > p {
  font-size: 0.85vw;
  font-weight: 500;
}
.Brand-Detail_card > div > .Email > p {
  font-size: 0.69vw;
  font-weight: 500;
  color: var(--channelToggleSwitch);
}
.Brand-RightDetail {
  align-items: center;
  justify-content: center;
}
.member {
  pointer-events: none;
  opacity: 0.7;
}
.Brand-RightDetail > p {
  font-size: 0.75vw;
  font-weight: 500;
  color: var(--channelToggleSwitch);
}

.BrandMember-popoverContainer {
  width: 13.5vw;
  padding: 0px 20px;
  height: auto;
}
.BrandMember-popoverContainer > div {
  padding: 7px 0px;
}
.BrandMember-popoverContainer > div > p {
  font-size: 0.69vw;
  font-weight: 500;
}
.BrandMember-popoverContainer > div > p:nth-child(2) {
  font-size: 0.69vw;
  font-weight: 400;
  color: var(--channelToggleSwitch);
}
.Member-container {
  width: 130px;
  height: 23px;
  background: var(--channelButton);
  position: relative;
  border-radius: 7px;
  padding-left: 15px;
}
.Member-container > p {
  color: var(--channelToggleSwitch);
  font-size: 0.69vw;
  font-weight: 500;
}
.Brand-Detail_card > div > div > img {
  width: 12px;
  margin: 0px 4px;
  display: none;
}
.Brand-Detail_card > div > div:not(.inactive) > .inactive {
  width: 12px;
  margin: 0px 4px;
  display: block;
}
.Active {
  display: block;
}
.Brand-Detail_card:hover > div > div > img {
  width: 12px;
  display: block;
}

.Switch {
  width: 40px;
  height: 20px;
  position: relative;
  background-color: var(--primary);
  border-radius: 20px;
}

.Brand-status {
  border-radius: 30px;
}
.On-button {
  padding: 2.5px;
  text-align: left;

  color: var(--background);
  font-size: 0.625vw;
  font-weight: 600;
}
.Off-button {
  padding: 2px;
  text-align: right;
  color: var(--background);
  font-size: 0.625vw;
  font-weight: 600;
}

/* moreCredits */

.brand-modal {
  width: 25% !important;
  border-radius: 5px;
  padding: 10px 10px;
}
.more_credit {
  padding: 10px 10px;
  gap: 10px;
}
.more_credit > div > span {
  font-size: 0.95vw;
  font-weight: 600;
}
.more_credit > div > p {
  font-size: 0.68vw;
  font-weight: 400;
  color: var(--darkgrey);
}
.credit_amt > div > p {
  border: 1px solid var(--font-50);
  border-radius: 15px;
  padding: 1px 8px;
  font-size: 0.75vw;
  font-weight: 500;
}

.brand_credit {
  width: 22vw;
}
.plan-sliders {
  width: 100%;
  height: 1px;
  border-radius: 40px;
  background: var(--background);
  overflow: hidden;
  margin-top: 2px;
}
.brand-button .brand_credit_line:hover .plan-slides {
  width: 100%;
  height: 100%;
  border-radius: 40px;
  background: linear-gradient(to right, #6940f2 100%, transparent 0%);
  animation: Animationslide 0.5s ease-in-out forwards;
}

.brand-button .Update_payment:hover .plan-slides {
  width: 100%;
  height: 100%;
  border-radius: 40px;
  background: linear-gradient(to right, #6940f2 100%, transparent 0%);
  animation: Animationslide 0.5s ease-in-out forwards;
}

/* @keyframes Animationslide {
  to {
    background-position:
      -100% 0,
      -100% 0;
  }
  0% {
    transform: translateX(-100%);
    background: linear-gradient(to right, #6940f2 100%, transparent 0%);
  }
  100% {
    transform: translateX(0%);
    background: linear-gradient(to right, #6940f2 100%, transparent 0%);
  }
} */

.Billing_invite_wrapper {
  width: 100%;
  border-radius: 16px;
  margin: 10px 0px 0px 0px;
  /* padding: 10px 0px; */

  background: var(--channelButton);
}

/* checked box */
input:checked {
  background-color: #22c55e; /* bg-green-500 */
}

input:checked ~ span:last-child {
  --tw-translate-x: 1.75rem; /* translate-x-7 */
}
