.round {
  position: relative;
}

.round label {
  background-color: var(--background);
  border: 2px solid var(--darkgrey);
  border-radius: 50%;
  cursor: pointer;
  height: 15px !important;
  left: 0;
  position: absolute;
  top: 0;
  width: 15px !important;
}

.round label:after {
  border: 2px solid var(--background);
  border-top: none;
  border-right: none;
  content: '';
  height: 4px;
  /* left: 50%; */
  opacity: 0;
  position: absolute;
  top: 6% !important;
  transform: rotate(-50deg) translate(-50%, -20%);
  width: 7px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.round input[type='radio'] {
  visibility: hidden;
}

.round input[type='radio']:checked + label {
  background-color: var(--primary);
  border-color: var(--primary);
}

.round input[type='radio']:checked + label:after {
  opacity: 1;
}
